/*
Flipboard.scss
*/
form {
	width: 100%;
	// border: 1px solid black;
	display: flex;
	align-items: flex-end;
	margin: 0 0 12px 0;
	textarea {
		width: 400px;
		height: 100px;
	}
	button {
		height: 24px;
		margin: 12px 12px 0 12px;
	}
}
.board {
	margin: auto;
}
