/*
Header.scss
*/
@import "../../styles/_responsive.module.scss";
@import "../../styles/_variables.module.scss";

header {
	height: 100px;
	margin: 3vh auto 0;
	max-width: $maxWidth;
	width: $commonWidth;
	display: flex;
	justify-content: space-between;
	// div {
	// 	border: 1px solid #000084;
	// 	img {
	// 		border: 1px solid gray;
	// 	}
	// }
	#BSClogo img {
		height: 11vh;
		margin: 0;
	}
	#BSCtext {
		// float: right;
		font-size: 1.8em;
		width: 80%;
		text-align: right;
		img {
			height: 11vh;
			// float: right;
			font-size: 6vh;
			line-height: 6vh;
			margin: 0;
			font-weight: bolder;
		}
	}
}

@media #{$screen-s} {
	#BSClogo img {
		margin: 0 0 0 -10px;
	}
	#BSCtext img {
		margin: 0 -10px 0 0;
	}
}

@media #{$screen-m} {
	#BSClogo img {
		margin-left: -20px;
	}
	#BSCtext img {
		margin: 0 -20px 0 0;
	}
}
