/*
Nav.scss
*/
@import "../../styles/_responsive.module.scss";
@import "../../styles/_variables.module.scss";

footer {
	width: $commonWidth;
	max-width: $maxWidth;
	margin: 3vh auto 0;
	clear: both;
	position: relative;
	display: flex;
	.logo {
		height: 1.2em;
		vertical-align: text-bottom;
	}
	font-size: 0.9rem;
	// a {
	// 	text-decoration: none;
	// }
}
@media #{$screen-m} {
	footer {
		font-size: 1rem;
	}
}
