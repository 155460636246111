/*
Zipper.scss
*/
$track-height: 28px;

.zipper {
	&-tooth-pair-element,
	&-tooth-top-element,
	&-tooth-bottom-element,
	&-slide-element {
		display: none;
	}
	width: 100%;
	height: 100%;
	background: white;
	position: relative;
	padding: 0 !important;
	opacity: 1;
	transition: opacity 1s;
	&.fadeout {
		.zipper-canvas,
		.zipper-track {
			transition: opacity 1s;
			opacity: 0;
		}
	}

	&-canvas {
		min-height: $track-height/2;
		position: absolute;
		width: 100%;
		left: 0;
		transition: opacity 1s;
		z-index: 0;
		&-top {
			// box-shadow: 0 0 8px red inset;
			top: 0;
		}
		&-bottom {
			// box-shadow: 0 0 8px blue inset;
			bottom: 0;
		}
	}
	&-content {
		height: 100%;
		overflow: hidden;
		padding: 0 10px;
	}
	&-track {
		height: $track-height;
		border-radius: 0 $track-height / 2 $track-height / 2 0;
		left: 0;
		background-image: url("./toothPair.png");
		background-repeat: repeat-x;
		background-position: left center;
		position: absolute;
		top: calc(#{50%} - #{$track-height / 2});
		$slider-height: 28px;
		$slider-width: 32px;
		min-width: $slider-width / 2;
		transition: opacity 1s;
		.zipper-slider {
			padding: 0;
			margin: 0;
			width: $slider-width;
			height: $slider-height;
			position: absolute;
			right: 0;
			top: calc(#{50%} - #{$slider-height/2});
			cursor: pointer;
			background-image: url("./slide.png");
			div.zipper-tab {
				position: absolute;
				top: 4px;
				right: 50%;
				width: 64px;
				height: 20px;
				background-image: url("./tab.png");
				transform: rotatey(0);
				transform-origin: right;
				transition: transform 0.5s;
				&.right {
					transform: rotatey(180deg);
					transition: transform 0.5s;
				}
			}
			z-index: 2;
		}
	}
}
