/*
Testimonials.scss
*/
$quotes-height: 50%;
$line-height: 20px;
$nquotes: 36;
$increment: 6;
$duration: $nquotes * $increment;
$quotes-width: 89vw;
body {
	margin: 0;
}
#testimonials {
	perspective: 30vw;
	perspective-origin: right;
	width: $quotes-width;
	height: 58vw;
	max-height: 560px;
	font-family: arial;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: -3vh auto 8vh;
	.container {
		width: $quotes-width;
		height: 58vw; //400px;
		max-width: 600px;
		max-height: 396px;
		transform: rotateY(-6deg) translateX(-8vw);
		//background: url("/images/testimonials/bubble.svg") no-repeat center;
		background-size: contain;
		//background: rgba(255,0,0,0.2);
		padding: 1%;
		img {
			position: absolute;
			z-index: 0;
			width: 100%;
			top: 0;
			left: 0;
			animation-name: fadeout;
			animation-duration: 2s;
			animation-iteration-count: 1;
			opacity: 0.1;
			transform: rotateY(-6deg);
		}
		ul {
			overflow: hidden;
			height: 100%;
			padding: 0;
			width: 100%;
			margin: 0;
			position: relative;
		}
		li {
			text-align: center;
			animation-name: teleprompt;
			animation-duration: #{$duration}s;
			animation-timing-function: linear;
			animation-iteration-count: infinite;
			display: flex;
			align-items: center;
			p {
				font-size: 4vh;
				margin: 0;
			}
			padding: 0 2%;
			margin: 0;
			list-style: none;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 96%;
			text-shadow: 36px 24px 2px rgba(0, 0, 100, 0.1);
			transform-origin: top center;
			transform: translateY(100%);
			opacity: 0;
			//background: blue;
		}
		&:before,
		&:after {
			content: "";
			background-image: url("./bubble.svg");
			background-size: cover;
			width: 100%;
			height: 118.3%;
			display: block;
			position: absolute;
			z-index: 2;
			background-position: 0 0;
			background-repeat: no-repeat;
			top: 0;
			left: 0;
		}
		&:before {
			background-image: url("./bubble-shadow.svg");
			transform: translate(14%, 8%); //rotateY(-2deg) translate(40px,20px) scale(0.95);
			z-index: 0;
			opacity: 0.1;
		}
	}
}

$percent: (100 * $increment / $duration);
$halfway: $percent / 2;
$halfwayunits: #{$halfway + "%"};
$percentunits: #{$percent + "%"};
$transparentmargin: 0.2;
$transparentmarginunits: #{$transparentmargin + "%"};
$percentmintransparentmargin: $percent - $transparentmargin;
$percentmintransparentmarginunits: #{$percentmintransparentmargin + "%"};
@keyframes teleprompt {
	0% {
		transform: translateY(100%);
		opacity: 0;
	}
	#{$transparentmarginunits} {
		opacity: 0;
	}
	#{$halfwayunits} {
		opacity: 1;
	}
	#{$percentmintransparentmarginunits} {
		opacity: 0;
	}
	#{$percentunits} {
		transform: translateY(-100%);
		opacity: 0;
	}
}

@keyframes fadeout {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0.1;
	}
}

// A little recursive SASS ?
@for $i from 1 through $nquotes {
	#testimonials li:nth-child(#{$i}) {
		animation-delay: #{$i * $increment}s;
	}
}

@media print {
	#testimonials {
		display: none;
	}
}
