/*
Nav.scss
*/
@import "../../styles/_colours.module.scss";
@import "../../styles/_mixins.module.scss";
@import "../../styles/_responsive.module.scss";
@import "../../styles/_variables.module.scss";

nav {
	text-align: left;
	box-shadow: 0 2px 6px rgba(0, 0, 50, 0.5);
	//padding-bottom: 2px;
	background-color: $navBgColour;
	// &>ul{
	// 	//width: @common-width;
	// }
	ul {
		max-width: $maxWidth;
		list-style: none;
		overflow: hidden;
		padding: 0;
		margin: 0 auto;
		display: flex;
		justify-content: center;
		li {
			&.home {
				width: 20%;
				a {
					text-indent: -9999px;
					background-image: url("../../images/home.svg");
					background-size: 50%;
					background-repeat: no-repeat;
					background-position: center center;
				}
			}
			// &.about{
			// 	width: 80%;
			// }
			&.services {
				width: 40%;
			}
			&.richardBagnall {
				width: 50%;
			}
			&.freeStuff {
				width: 50%;
			}
			&.contact {
				width: 100%;
				float: left;
			}
			display: block;
			color: white;
			text-indent: 0;
			margin: 0;
			padding: 0 4vw;
			//.heightAndLineHeight( $navLiHeight );
			border: none;
			a {
				@include heightAndLineHeight($navLiHeight);
				border-width: $navBorderWidth;
				border-color: $navBorderLight $navBorderDark $navBorderDark $navBorderLight;
				border-style: solid;
				background-color: $navBgColour;
				padding: 0;
				font-size: 1.4em;
				text-decoration: none;
				display: block;
				text-align: center;
				//float: left;
			}
			a,
			a:link,
			a:visited,
			a:hover,
			a:active {
				color: white;
			}
			a:hover {
				background-color: blue;
				color: red;
			}
			&.left {
				float: left;
				a {
					//border-left-width: 0;
				}
			}
			&.center {
				float: left;
				//text-align: center;
				//margin: 0 -1px;
			}
			&.right {
				float: right;
				a {
					//border-right-width: 0;
				}
			}
			& > ul {
				display: none;
				li {
					a {
						text-align: left;
					}
				}
			}
		}
	}
}

// a.mainmenu:link,
// a.mainmenu:active,
// a.mainmenu:visited,
// a.mainmenu:hover,
// a.submenu:link,
// a.submenu:active,
// a.submenu:visited,
// a.submenu:hover {
// 	background-color: #000099;
// 	color: white;
// 	display: block;
// 	font-size: 0.9em;
// 	padding: 4px 16px 1px;
// 	text-decoration: none;
// }
// a.mainmenu:hover,
// a.submenu:hover {
// 	background-color: #0000ff;
// 	color: red;
// }
@media #{$screen-s} {
	nav {
		font-size: 1em;
		& > ul {
			& > li {
				&.home {
					a {
						background-image: none;
						text-indent: 0;
					}
				}
			}
		}
	}
}

@media #{$screen-m} {
	nav {
		ul {
			li {
				height: auto;
				width: auto;
				line-height: 1em;
				&.home,
				&.about,
				&.services,
				&.richardBagnall,
				&.freeStuff,
				&.contact {
					width: auto;
					float: left;
				}
				a {
					font-size: 1.4em;
					padding: 0.4em 4vw;
					height: auto;
					width: auto;
					line-height: 1em;
					border-width: 1px 1px 1px 1px;
					&.right,
					&.left,
					&.full {
						border-width: 1px 1px 1px 1px;
					}
				}
				&:hover > ul {
					display: block;
					box-shadow: 1px 2px 6px rgba(0, 0, 50, 0.5);
					position: absolute;
					z-index: 2;
				}
			}
		}
	}
}

@media print {
	nav {
		display: none;
	}
}
