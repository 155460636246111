/*
Bookmarklets.scss
*/
iframe {
	width: 100%;
	height: 100%;
	min-height: 50vh;
	overflow: hidden;
	border: none;
}
