/*
FocusPull.scss
*/
#focus {
	max-width: 1000px;
	max-height: 750px;
	width: 100vw;
	height: 75vw;
	position: relative;
	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 0;
		&:first-child {
			animation: focus;
			animation-duration: 4s;
			animation-iteration-count: infinite;
			animation-direction: alternate;
			z-index: 1;
		}
	}
}
@keyframes focus {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
