/*
_colours.module.scss
*/
$paleBlue: #e0e0ff;
$darkBlue: #000084;
$inputBorderColour: #eee;
$navBgColour: #000099;
$navBorderLight: lighten($navBgColour, 15%);
$navBorderDark: darken($navBgColour, 15%);
$textColour: #000099;
