/*
Bulbs.scss
*/

.content {
	&.bulbs {
		background-image: url("bricks.jpg");
		position: relative;
		overflow: hidden;
		margin: 0;
		font-family: arial;
		font-size: 16px;
		color: white;
		color: black;
		background: white;
		margin: 50vh 4vw 0;
		position: absolute;
		text-align: justify;
		display: none;
	}
	.quote {
		font-style: italic;
	}
}
