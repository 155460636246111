/*
Wardrobe.scss
*/
$unit: 400px;
$unitmax: 100px;
$quarterunit: $unit / 4;

#list {
	width: $unit;
	height: $unit;
	max-width: 4 * $unitmax;
	max-height: 4 * $unitmax;
	box-shadow: 0 0 12px blue inset;
	position: relative;
	display: inline-block;
	float: left;
	margin-right: 2vw;
	padding: 0;
	li {
		margin: 0;
		padding: 0;
		overflow: hidden;
		display: block;
		width: $quarterunit;
		height: $quarterunit;
		width: 25%;
		height: 25%;
		box-shadow: 0 0 24px red inset;
		text-align: center;
		// line-height: $quarterunit;
		// top: $quarterunit;
		position: absolute;
		transition: all 0.5s;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		text-indent: 0;
		&:not(.history) {
			box-shadow: 0 0 24px green inset;
			// top: 0;
			transition: all 0.5s;
			border-radius: 50%;
		}
	}
}
