/*
Reflective.scss
*/
#reflectiveContainer {
	background: black;
	color: white;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 80vh;
	img {
		position: absolute;
	}
	img.fake {
		z-index: 1;
	}
	#gum {
		background: #c00;
		color: #fff;
		padding: 10px;
	}

	#maskcontainer {
		display: block;
		width: 50vw;
		height: 59.6vw;
		max-width: 500px;
		max-height: 550px;
		position: relative;
		margin: 0 auto;
	}
	#textcontainer {
		display: block;
		max-width: 1200px;
		max-height: 228px;
		width: 100%;
		height: 19%;
		position: relative;
		margin: 0 auto;
	}
	#mask,
	#text,
	#textCanvas,
	#fake,
	#maskCanvas {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
	}
	#textCanvas,
	#maskCanvas {
		width: 100%;
		height: 100%;
		transform: scaleX(-1);
	}
	#mask,
	#text,
	#fake {
		left: -99999px;
		position: absolute;
	}
	video,
	canvas {
		display: none;
	}
	div > img,
	canvas > img {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1;
	}
	div > img.fake,
	canvas > img.fake {
		z-index: 0;
	}
}
