/*
Spinner.scss
*/
$spinner-diameter: 24vw;
$spinner-radius: $spinner-diameter/2;
@mixin common-background {
	background-image: url("logo_square.svg");
	background-size: 100%;
	background-color: white;
	background-repeat: no-repeat;
}

body {
	margin: 0;
}
#container {
	min-height: 25vw;
	display: flex;
	justify-content: center;
}
#spinner {
	width: $spinner-diameter;
	height: $spinner-diameter;
	position: absolute;
	@include common-background();
	border-radius: $spinner-radius;
	&:before,
	&:after {
		content: "";
		width: $spinner-radius;
		height: $spinner-diameter;
		position: absolute;
		display: block;
		border-radius: 0 $spinner-radius $spinner-radius 0;
		animation-duration: 10s;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
		background-size: 200% 100%;
	}
	&:before {
		left: $spinner-radius;
		background-color: blue;
		transform-origin: center left;
		animation-name: spinner-before;
	}
	&:after {
		border-radius: $spinner-radius 0 0 $spinner-radius;
		right: $spinner-radius;
		transform-origin: center right;
		animation-name: spinner-after;
	}
}
@keyframes spinner-before {
	0% {
		transform: rotateZ(180deg);
	}
	24.99% {
		transform: rotateZ(360deg);
	}
	49.99% {
		transform: rotateZ(360deg);
	}
	50% {
		transform: rotateZ(360deg);
	}
	74.99% {
		transform: rotateZ(540deg);
	}
	75% {
		transform: rotateZ(180deg);
	}
	100% {
		transform: rotateZ(360deg);
	}
}
@keyframes spinner-after {
	0% {
		transform: rotateZ(0deg);
		@include common-background;
		background-size: 200% 100%;
		background-position: left;
		right: $spinner-radius;
		border-radius: $spinner-radius 0 0 $spinner-radius;
	}
	24.99% {
		transform: rotateZ(0deg);
		@include common-background;
		background-size: 200% 100%;
		background-position: left;
	}
	25% {
		transform: rotateZ(180deg);
		background-color: blue;
		background-image: none;
	}
	49.99% {
		transform: rotateZ(360deg);
	}
	50% {
		transform: rotateZ(360deg);
	}
	74.99% {
		transform: rotateZ(360deg);
		background-color: blue;
		background-image: none;
		right: $spinner-radius;
		border-radius: $spinner-radius 0 0 $spinner-radius;
	}
	75% {
		transform: rotateZ(0deg) scaleX(-1);
		@include common-background;
		background-size: 200% 100%;
	}
	100% {
		transform: rotateZ(0deg) scaleX(-1);
		@include common-background;
		background-size: 200% 100%;
	}
}
