/*
Digit.scss
*/
$digit-height: 22.3px;
$digit-width: 11px;
$digit-transition: 0.5s;
.digit-container {
	height: $digit-height;
	width: $digit-width;
	line-height: $digit-height;
	overflow: hidden;
	border: 1px solid black;
	padding: 0 2px;
	color: white;
	font-family: arial;
	background: linear-gradient(darkgray, gray, black);
	position: relative;
	&:after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		display: block;
		box-shadow: 0 0 2px inset black;
		top: 0;
		left: 0;
	}
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		&.digit {
			text-indent: 0;
			text-align: center;
		}
		&.digit-9-wrap {
			margin-top: 0;
		}
		&.digit-0 {
			margin-top: -$digit-height;
		}
		&.digit-1 {
			margin-top: -$digit-height * 2;
		}
		&.digit-2 {
			margin-top: -$digit-height * 3;
		}
		&.digit-3 {
			margin-top: -$digit-height * 4;
		}
		&.digit-4 {
			margin-top: -$digit-height * 5;
		}
		&.digit-5 {
			margin-top: -$digit-height * 6;
		}
		&.digit-6 {
			margin-top: -$digit-height * 7;
		}
		&.digit-7 {
			margin-top: -$digit-height * 8;
		}
		&.digit-8 {
			margin-top: -$digit-height * 9;
		}
		&.digit-9 {
			margin-top: -$digit-height * 10;
		}
		&.digit-0-wrap {
			margin-top: -$digit-height * 11;
		}
		&.smooth {
			&.digit,
			&.digit-9-wrap,
			&.digit-0,
			&.digit-1,
			&.digit-2,
			&.digit-3,
			&.digit-4,
			&.digit-5,
			&.digit-6,
			&.digit-7,
			&.digit-8,
			&.digit-9,
			&.digit-0-wrap {
				transition: margin-top $digit-transition;
			}
		}
	}
}
