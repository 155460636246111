/*
Character.scss
*/

$transition-speed: 0.5s;
:export {
	transitionSpeed: $transition-speed; /* stylelint-disable-line */
}

$background: rgb(80, 80, 80);
$font-size: 2rem;
$border-radius: $font-size / 12;
$box-shadow: 0 0 $font-size / 16 rgba(0, 0, 0, 0.7) inset;

@keyframes flip {
	0% {
		transform: rotateX(0deg);
		visibility: visible;
		background: $background;
	}
	49.9% {
		background: darken($background, 10);
		color: rgb(180, 180, 180);
	}
	50% {
		background: lighten($background, 10);
		color: white;
	}
	99.9% {
		transform: rotateX(180deg);
		visibility: visible;
		background: $background;
	}
	100% {
		transform: rotateX(180deg);
		visibility: hidden;
		background: $background;
	}
}

@keyframes old-character {
	0% {
		visibility: visible;
		line-height: 0;
	}
	99.9% {
		visibility: visible;
		line-height: 0;
	}
	100% {
		visibility: hidden;
	}
}

@keyframes new-character {
	0% {
		visibility: visible;
		height: 50%;
	}
	99.9% {
		visibility: hidden;
		height: 50%;
	}
	100% {
		visibility: visible;
		height: 100%;
	}
}

.character {
	background: $background;
	color: white;
	font-size: $font-size;
	font-family: arial;
	display: inline-block;
	overflow: hidden;
	position: relative;
	box-shadow: $box-shadow;
	text-align: center;
	span {
		&.sizer {
			text-indent: -9999px;
			color: transparent;
			&::before,
			&::after {
				content: "";
				width: $font-size/30;
				height: $font-size/10;
				background: rgba(0, 0, 0, 0.4);
				position: absolute;
				top: calc(50% - #{$font-size} / 20);
				border-radius: $font-size/60;
			}
			&::before {
				left: $font-size/20;
			}
			&::after {
				right: $font-size/20;
			}
		}
	}
	&::before,
	&::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 50%;
		top: 50%;
		left: 0;
		z-index: 4;
		box-shadow: 0 0 $font-size / 32 rgba(0, 0, 0, 0.7);
	}
	&::after {
		top: 0;
	}
	.old-character,
	.new-character {
		position: absolute;
		width: 100%;
		overflow: hidden;
	}
	.old-character {
		height: 50%;
		top: 50%;
		visibility: hidden;
		line-height: 1.3;
		z-index: 1;
	}
	.new-character {
		top: 0;
		z-index: 2;
	}
	.old-flipper,
	.new-flipper {
		height: 100%;
		overflow: hidden;
		position: absolute;
		width: 100%;
	}
	.new-flipper {
		backface-visibility: hidden;
		line-height: 0;
		transform: rotateX(180deg);
	}
	.old-flipper {
		backface-visibility: hidden;
		transform-origin: bottom left;
	}
	.flip {
		box-shadow: 0 $font-size / 100 $font-size / 16 rgba(0, 0, 0, 0.7) inset;
		position: absolute;
		bottom: 50%;
		transform-origin: bottom left;
		height: 50%;
		width: 100%;
		color: white;
		display: block;
		visibility: hidden;
		transform-style: preserve-3d;
		background: rgb(122, 122, 122);
		z-index: 3;
		border-radius: $border-radius $border-radius 0 0;
		&::before,
		&::after {
			content: "";
			width: $font-size/30;
			height: $font-size/20;
			background: rgba(0, 0, 0, 0.4);
			position: absolute;
			bottom: 0;
			border-radius: $font-size/60 $font-size/60 0 0;
		}
		&::before {
			left: $font-size/20;
		}
		&::after {
			right: $font-size/20;
		}
	}
	&.animate {
		.old-character {
			animation-name: old-character;
			animation-duration: $transition-speed;
			animation-fill-mode: forwards;
		}
		.new-character {
			animation-name: new-character;
			animation-duration: $transition-speed;
			animation-fill-mode: forwards;
		}
		.flip {
			animation-fill-mode: forwards;
			animation-timing-function: ease-in-out;
			animation-name: flip;
			animation-duration: $transition-speed;
		}
	}
}
