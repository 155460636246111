/*
_mixins.module.scss
*/

@mixin border-radius($arguments...) {
	border-radius: $arguments;
}

@mixin heightAndLineHeight($arguments...) {
	height: $arguments;
	line-height: $arguments;
}

@mixin fontSizeAndLineHeight($arguments...) {
	font-size: $arguments;
	line-height: $arguments;
}

@mixin transition($arguments...) {
	-webkit-transition: $arguments;
	transition: $arguments;
}

@mixin transform($arguments...) {
	-ms-transform: $arguments; /* IE 9 */
	-webkit-transform: $arguments;
	transform: $arguments;
}

@mixin perspective($arguments...) {
	-webkit-perspective: $arguments;
	perspective: $arguments;
}

@mixin ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
	-ms-text-overflow: ellipsis;
}

@mixin inline-block {
	display: inline-block;
	*display: inline; // For fIE7
	zoom: 1;
}
