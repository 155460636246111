/*
App.scss
*/
@import "./styles/_variables.module.scss";
// @import './styles/media-queries';
// @import "./styles/reset.scss";
// @import "./styles/layout.scss";
@import "./styles/_mixins.module.scss";
// @import "./styles/forms.scss";
@import "./styles/_colours.module.scss";

// @import "./styles/_common.scss";

body {
	color: $textColour;
	font-family: Geneva, Arial, Helvetica, sans-serif;
	font-size: 14px;
	line-height: 1.4;
	margin: 0px;
	box-sizing: border-box;

	scrollbar-base-color: #000099;
	scrollbar-arrow-color: #ffffff;
	scrollbar-3dlight-color: #6666ff;
	scrollbar-darkshadow-color: #000066;
	scrollbar-face-color: #000099;
	scrollbar-track-color: #eeeeff;
}

h1 {
	font-size: 1.6em;
	font-weight: bold;
	font-family: arial, verdana;
}

h2 {
	font-size: 1.2em;
	font-weight: bold;
	font-family: arial, verdana;
}

h3 a,
.more,
.clickLess,
.clickMore,
a {
	&:link,
	&:visited,
	&:hover,
	&:active {
		text-decoration: none;
		color: #0033cc;
	}
}

a,
h3 a {
	&:hover {
		color: #3399ff;
	}
}
.more,
.clickLess,
.clickMore {
	cursor: pointer;
}

#moreInfo,
.content {
	font-size: 1rem;
	width: $commonWidth;
	max-width: $maxWidth;
	margin: 3vh auto 0;
	clear: both;
	position: relative;
	.logo {
		height: 1.2em;
		vertical-align: text-bottom;
	}
}

.quote {
	font-style: italic;
}
