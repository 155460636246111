/*
Zippers.scss
*/
$trackHeight: 42px;

.zipWrapper {
	position: relative;
	.zipper {
		width: 100%;
		height: 100%;
		background: white;
		position: relative;
		overflow: hidden;
		p,
		h1,
		h2 {
			color: inherit;
		}
	}
	canvas {
		min-height: $trackHeight/2;
		position: absolute;
		&.top {
			top: 0;
		}
		&.bottom {
			bottom: 0;
		}
	}
	div.track {
		top: 0;
		height: $trackHeight/2;
		position: absolute;
		width: 100%;
		div.slider {
			width: 32px;
			height: 28px;
			position: absolute;
			right: 0%;
			top: 7px;
			cursor: pointer;
			background-image: url("./slide.png");
			div.tab {
				position: absolute;
				top: 4px;
				right: 50%;
				width: 64px;
				height: 20px;
				background-image: url("./tab.png");
				transform: rotatey(0);
				transform-origin: right;
				transition: transform 0.5s;
				&.right {
					transform: rotatey(180deg);
					transition: transform 0.5s;
				}
			}
		}
	}
}
#zippyzip {
	background: #ffffc0;
	// > div {
	padding: 0 10px;
	h2 {
		font-size: 2em;
	}
	// }
}
#whitezip {
	background: #00c4c4;
	color: white;
	// > div {
	padding: 0 10px;
	// }
}
#purplezip {
	background: #750075;
	color: white;
	// > div {
	padding: 0 10px;
	// }
}
