/*
BAC.scss
*/
#largeMap {
	max-width: 1000px;
	max-height: 750px;
	width: 100vw;
	height: 75vw;
	position: relative;
}
#smallMap,
#calendar {
	max-width: 640px;
	max-height: 240px;
	width: 640px;
	height: 240px;
	position: relative;
}
.map,
.calendar {
	border: 3px ridge blue;
}
