/*
ContextHelp.scss
*/
body {
	font-size: 16px;
	font-family: arial;
}
#metail-container {
	background: url("./wanda.jpg") no-repeat 0 0;
	left: 220px;
	top: 60px;
	height: 400px;
	width: 380px;
	position: absolute;

	.dialog-container {
		width: 178px;
		text-align: center;
		right: 187px;
		top: 110px;
		position: absolute;
	}
	.dialog-container.show {
		width: 178px;
	}
	.help-popup {
		opacity: 0;
		// display: none;
		transition: opacity 1s;
		&.show {
			opacity: 1;
			transition: opacity 1s;
		}
		top: 0;
		position: absolute;
		border: 2px solid #f23ea8;
		border-radius: 8px;
		width: 142px;
		min-height: 48px;
		background: white;
		padding: 12px 12px 0px;
		text-align: center;
		&:before,
		&:after {
			position: absolute;
			content: "";
			width: 0;
			height: 0;
			top: 39.5px;
			border-width: 8px;
			border-style: solid;
		}
		&:before {
			right: -18px;
			border-color: transparent transparent transparent #f23ea8;
		}
		&:after {
			right: -15px;
			border-color: transparent transparent transparent white;
		}
		p {
			font-family: arial;
			font-size: 0.781em;
			text-align: center;
			margin: 3px 3px 8px 3px;
		}
		svg.close {
			position: absolute;
			top: 5px;
			right: 3px;
			width: 8px;
			height: 8px;
			cursor: pointer;
		}
		svg.cta-sample {
			display: inline-block;
			height: 34px;
			width: 42px;
		}
		svg.take-it-off-icon {
			// box-shadow: 0 0 12px red;
			vertical-align: middle;
			margin: 0 0 0 2px;
			// padding: 0;
		}
	}
	.arrow {
		position: absolute;
		display: inline-block;
		left: 172px;
		top: -24px;
		height: 38px;
		width: 44px;
		padding: 0;
		margin: 0 0 6px;
		border: 0;
		// background: url("up-right.svg") no-repeat;
		transform: translateZ(0);
	}
	.down-left {
		// background: url("long-down-left.svg") no-repeat;
		height: 115px;
		left: 132px;
		top: 66px;
	}
	.retailer-background-colour {
		fill: #f23ea8;
	}
	.retailerColour {
		color: #f23ea8;
	}
	&.showTryMeBack {
		background: url("help-popup.jpg") no-repeat 0 0;
	}
	&.showSizeAndFitBack {
		background: url("size-and-fit.jpg") no-repeat 0 0;
	}
	&.showTakeItOffBack {
		background: url("take-it-off.jpg") no-repeat 0 0;
	}
}
.buttons {
	button,
	label {
		clear: both;
		display: block;
		margin: 8px 0 0;
	}
	label {
		font-size: 0.8em;
	}
	buttons label,
	input {
		cursor: pointer;
	}
}
table {
	tr {
		td {
			width: 80px;
			height: 100px;
			// background-image: url("cta.svg");
			background-repeat: no-repeat;
			background-size: 26px 26px;
			background-position: 8px 8px;
			box-shadow: 0 0 8px rgba(0, 0, 255, 0.2) inset;
			&.highlight {
				animation-name: throb;
				animation-duration: 1s;
				animation-iteration-count: 5;
				animation-timing-function: ease;
			}
		}
	}
}
